import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, width = 40, height = 40, color = MAIN_CONFIG.COLORS.fillGreen }) => (
    <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
        <title>Tiktok</title>
        <desc>Tiktok</desc>
        <defs>
            <clipPath id="clip-tiktok-icon_1">
                <rect width="40" height="40" />
            </clipPath>
        </defs>
        <g id="tiktok-icon_1" data-name="tiktok-icon – 1" clipPath="url(#clip-tiktok-icon_1)">
            <path id="tiktok_1_" data-name="tiktok (1)" d="M16.421,0c.418,3.6,2.427,5.745,5.92,5.973v4.048a9.252,9.252,0,0,1-5.859-1.712v7.571C16.481,25.5,6,28.5,1.782,21.609-.927,17.173.732,9.389,9.421,9.077v4.268A12.572,12.572,0,0,0,7.4,13.84c-1.933.654-3.028,1.879-2.724,4.04.586,4.139,8.179,5.364,7.548-2.724V.008h4.192Z" transform="translate(8.534 7.319)" fill={color} fillRule="evenodd" />
        </g>
    </svg>
);
