import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, width = 24, height = 24, color = MAIN_CONFIG.COLORS.fillGreen }) => (
    <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <title>Instagram</title>
        <desc>Instagram</desc>
        <g fill={color} fillRule="evenodd">
            <g fill={color}>
                <g>
                    <g>
                        <path
                            d="M13.133 4c1.179.005 1.49.017 2.166.048.851.039 1.433.174 1.942.372.526.204.972.478 1.417.922.444.445.718.891.922 1.417.198.51.333 1.09.372 1.942.031.676.043.987.047 2.166l.001.402V13.133c-.005 1.179-.017 1.49-.048 2.166-.039.851-.174 1.433-.372 1.942-.204.526-.478.972-.922 1.417-.445.444-.891.718-1.417.922-.51.198-1.09.333-1.942.372-.676.031-.987.043-2.166.047l-.402.001h-1.462c-1.519-.002-1.82-.014-2.568-.048-.851-.039-1.433-.174-1.942-.372-.526-.204-.972-.478-1.417-.922-.444-.445-.718-.891-.922-1.417-.198-.51-.333-1.09-.372-1.942-.031-.676-.043-.987-.047-2.166v-2.266c.004-1.179.016-1.49.047-2.166.039-.851.174-1.433.372-1.942.204-.526.478-.972.922-1.417.445-.444.891-.718 1.417-.922.51-.198 1.09-.333 1.942-.372.676-.031.987-.043 2.166-.047zm-.21 1.442h-1.846c-.854.002-1.277.007-1.661.02l-.265.009-.384.017c-.78.035-1.204.166-1.485.275-.374.145-.64.319-.92.599-.28.28-.454.546-.599.92-.11.281-.24.705-.275 1.485l-.017.384-.01.265c-.012.384-.017.807-.02 1.661v1.846c.003.854.008 1.277.02 1.661l.01.265.017.384c.035.78.166 1.204.275 1.485.145.374.319.64.599.92.28.28.546.454.92.599.281.11.705.24 1.485.275l.384.017.265.01c.384.012.807.017 1.661.02h1.846c.854-.003 1.277-.008 1.661-.02l.265-.01.384-.017c.78-.035 1.204-.166 1.485-.275.374-.145.64-.319.92-.599.28-.28.454-.546.599-.92.11-.281.24-.705.275-1.485l.017-.384.01-.265c.012-.384.017-.807.02-1.661v-1.846c-.003-.854-.008-1.277-.02-1.661l-.01-.265-.017-.384c-.035-.78-.166-1.204-.275-1.485-.145-.374-.319-.64-.599-.92-.28-.28-.546-.454-.92-.599-.281-.11-.705-.24-1.485-.275l-.384-.017-.265-.01c-.384-.012-.807-.017-1.661-.02zM12 7.892c2.269 0 4.108 1.839 4.108 4.108 0 2.269-1.839 4.108-4.108 4.108-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.839-4.108 4.108-4.108zm0 1.441c-1.473 0-2.667 1.194-2.667 2.667 0 1.473 1.194 2.667 2.667 2.667 1.473 0 2.667-1.194 2.667-2.667 0-1.473-1.194-2.667-2.667-2.667zm4.27-2.564c.53 0 .96.43.96.96s-.43.96-.96.96-.96-.43-.96-.96.43-.96.96-.96z"
                            transform="translate(-208 -767) translate(164 767) translate(44)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
