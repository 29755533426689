import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillBlue, width = "200", height = "200", secondaryColor = MAIN_CONFIG.COLORS.fillRed }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>Google</title>
            <desc>Google</desc>
            <defs>
                <clipPath id="clip-google">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="google" clipPath="url(#clip-google)">
                <g id="Google__G__Logo" transform="translate(37.754 -29.239)">
                    <path id="Path_45867" data-name="Path 45867" d="M71.841,66.347a104.78,104.78,0,0,0-1.432-17.108H-14.754v33.99H34.008A41.986,41.986,0,0,1,15.92,110.21v22.61H45.011C62.044,117.068,71.841,93.856,71.841,66.347Z" transform="translate(78.439 65.366)" fill="#4285f4" />
                    <path id="Path_45868" data-name="Path 45868" d="M55.253,126.709c24.419,0,44.843-8.139,59.765-21.931L85.927,82.168c-8.14,5.426-18.465,8.742-30.674,8.742-23.589,0-43.561-15.9-50.721-37.381h-30V76.817A90.3,90.3,0,0,0,55.253,126.709Z" transform="translate(8.432 93.408)" fill="#34a853" />
                    <path id="Path_45869" data-name="Path 45869" d="M12.964,103.665A52.48,52.48,0,0,1,10.1,86.406a54.522,54.522,0,0,1,2.864-17.259V45.859h-30a89.412,89.412,0,0,0,0,81.094Z" transform="translate(0 43.272)" fill="#fbbc05" />
                    <path id="Path_45870" data-name="Path 45870" d="M55.253,75.038A49.068,49.068,0,0,1,89.921,88.6L115.7,62.828c-15.6-14.621-36.025-23.59-60.443-23.59A90.3,90.3,0,0,0-25.464,89.131l30,23.288C11.691,90.94,31.663,75.038,55.253,75.038Z" transform="translate(8.432 0)" fill="#ea4335" />
                </g>
            </g>
        </svg>
    );
};
