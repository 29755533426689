import React from 'react';

export default ({ width = 34, height = 34, color = '#F7CAC8' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
        <title>Twitter</title>
        <defs>
            <clipPath id="clip-Custom_Size_1">
            <rect width="24" height="24" />
            </clipPath>
        </defs>
            <path id="Path_1" fill={color} data-name="Path 1" d="M22.532,2.25h4.143L17.624,12.595,28.272,26.672H19.935L13.4,18.134,5.933,26.672H1.788l9.681-11.065L1.254,2.25H9.8l5.9,7.8ZM21.078,24.192h2.3L8.556,4.6H6.092Z" transform="translate(4.746 5.75)"/>
    </svg>
);
