import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, width = 40, height = 40, color = MAIN_CONFIG.COLORS.fillGreen }) => (
    <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
        <title>Globe</title>
        <desc>Globe</desc>
        <defs>
            <clipPath id="clip-website">
                <rect width="40" height="40" />
            </clipPath>
        </defs>
        <g id="website" clipPath="url(#clip-website)">
            <path id="Icon_ionic-ios-globe" data-name="Icon ionic-ios-globe" d="M15,2.813h-.041a12.188,12.188,0,0,0,.006,24.375h.041A12.188,12.188,0,1,0,15,2.813ZM25.559,14.18H21.012a23.074,23.074,0,0,0-.574-4.465,17.85,17.85,0,0,0,2.912-1.23A10.5,10.5,0,0,1,25.559,14.18Zm-11.379,0H10.559a20.827,20.827,0,0,1,.516-4.049,17.826,17.826,0,0,0,3.105.416Zm0,1.641v3.627a17.9,17.9,0,0,0-3.105.416,20.824,20.824,0,0,1-.516-4.043Zm1.641,0h3.592a20.7,20.7,0,0,1-.516,4.037,17.49,17.49,0,0,0-3.076-.41Zm0-1.641V10.547a17.9,17.9,0,0,0,3.076-.41,20.766,20.766,0,0,1,.516,4.043Zm6.41-6.908A16.278,16.278,0,0,1,20,8.18a12.1,12.1,0,0,0-1.553-3.193A10.6,10.6,0,0,1,22.23,7.271ZM18.457,8.6a16.49,16.49,0,0,1-2.637.352v-4.3A7.1,7.1,0,0,1,18.457,8.6ZM14.18,4.629V8.947a16.206,16.206,0,0,1-2.666-.357A7.1,7.1,0,0,1,14.18,4.629ZM11.508,5a12.194,12.194,0,0,0-1.541,3.17,16.644,16.644,0,0,1-2.2-.9A10.444,10.444,0,0,1,11.508,5ZM6.65,8.49A17.5,17.5,0,0,0,9.533,9.709a22.145,22.145,0,0,0-.574,4.465H4.447A10.443,10.443,0,0,1,6.65,8.49ZM4.441,15.82H8.953a23.015,23.015,0,0,0,.574,4.465A18.564,18.564,0,0,0,6.645,21.5,10.5,10.5,0,0,1,4.441,15.82Zm3.322,6.908a16.4,16.4,0,0,1,2.2-.9A12.237,12.237,0,0,0,11.508,25,10.7,10.7,0,0,1,7.764,22.729Zm3.75-1.324a16.291,16.291,0,0,1,2.666-.357v4.324C13.172,24.838,12.193,23.385,11.514,21.4Zm4.307,3.943V21.041a16.49,16.49,0,0,1,2.637.352A7.1,7.1,0,0,1,15.82,25.348Zm2.637-.334A12.1,12.1,0,0,0,20.01,21.82a16.009,16.009,0,0,1,2.227.914A10.725,10.725,0,0,1,18.457,25.014Zm4.893-3.5a17.85,17.85,0,0,0-2.912-1.23,22.954,22.954,0,0,0,.574-4.459h4.547A10.452,10.452,0,0,1,23.35,21.51Z" transform="translate(4.997 5)" fill={color} />
        </g>
    </svg>
);
