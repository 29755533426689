import React from 'react';

export default ({ width = 34, height = 34, color = '#F7CAC8' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <title>Facebook</title>
        <g fill="none" fillRule="evenodd">
            <g fill={color}>
                <g>
                    <g>
                        <path
                            d="M13.333 9.333V7.746c0-.717.159-1.08 1.271-1.08H16V4h-2.33c-2.853 0-3.795 1.308-3.795 3.554v1.78H8V12h1.875v8h3.458v-8h2.35L16 9.333h-2.667z"
                            transform="translate(-252 -767) translate(164 767) translate(88)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
