import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, strokeColor = MAIN_CONFIG.COLORS.fillWhite }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9">
            <title>Check Mark</title>
            <desc>Check Mark</desc>
            <g fill="none" fillRule="evenodd">
                <g stroke={color} strokeWidth="2">
                    <g>
                        <g>
                            <path d="M10 14.438L13.557 18 21 12" transform="translate(-362 -1490) translate(333 958) translate(20 521)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    );
}
