import React from 'react';
import { toast } from 'react-toastify';
import CTAButton from '../CTAButton/CTAButton';
import  './view-url-modal.scss'
import FlashMessage from '../../Components/FlashMessage/Message';
import { handleKeyDown } from '../../Utility/ApplicationFormUtility';
import PropTypes from 'prop-types';
import Close from '../SVG/Close';
import { MAIN_CONFIG } from '../../../config/main';

const ViewUrlModal = ({
    handleClose = () => { },
    url = '',
    ModalTitle='Share Report'
}) => {

    const copyToClipboardPromise = () => {
        
        if (navigator && navigator.clipboard && navigator.clipboard.writeText){
            return navigator.clipboard.writeText(url);
        }
        return Promise.reject('The Clipboard API is not available.');
      };

    const copyToClipboard = () => {
        copyToClipboardPromise()
        .then(() =>{
            toast.success(<FlashMessage message={`URL copied`} />);
        })
        .catch(() => {
            toast.error(<FlashMessage type="error" message={"Unable to Copy URL. Update your browser."} />)
        })
    }
    return (
            <div className='share-report-modal-content'>
                <div className="share-report-head_container">
                    <div className='share'>
                    </div>
                    <div id="share-report__title" className='H2DesktopGreen'>
                        {ModalTitle}
                    </div>
                    <div className='share-report-close'>
                        <button onClick={(e)=>handleClose(e)} onKeyDown={(e)=>handleKeyDown(e,()=>handleClose(e))}><Close color={MAIN_CONFIG.COLORS.fillGrey} ariaLabel='Close' ariaLabelDescription='' /></button>
                    </div>
                </div>

                <div className="share-report__main">
                    <div className='main-title'>
                        URL
                    </div>
                    <div className="main-content">
                        <div className='main-content__link'>
                            {url}
                        </div>
                        <CTAButton className="main-content__button" small onClick={copyToClipboard}>Copy URL</CTAButton>
                    </div>

                </div>
            </div>
        )
}

// handle PropTypes
ViewUrlModal.propTypes = {
    url: PropTypes.string,
    ModalTitle: PropTypes.string,
    handleClose: PropTypes.func
  };

export default ViewUrlModal;