import React from "react";
import BrokenPencil from "../SVG/BrokenPencil";
import WordMark from "../SVG/WordMark";
import { MAIN_CONFIG } from "../../../config/main";
import DrawnArrow from "../SVG/DrawnArrow";
import Divider from "../Divider/Divider";
import Twitter from "../SVG/Twitter";
import Instagram from "../SVG/Instagram";
import Linkedin from "../SVG/Linkedin";
import Facebook from "../SVG/Facebook";
import BrandMark from "../SVG/BrandMark";
import "./footer.scss";



const { USER_CONTEXT } = MAIN_CONFIG


export default ({ poweredByKaleidoscope = false, userContext }) => {

  const heading = MAIN_CONFIG.FOOTER.HEADING;
  const topLinks = MAIN_CONFIG.FOOTER.TOP_LINKS;
  const bottomlinks = MAIN_CONFIG.FOOTER.BOTTOM_LINKS;

  // const renderTopLinks = () => {
  //   return (
  //     <nav className="footer__top-links" aria-label="Footer Top Navigation">
  //       {topLinks.map((link, i) => {
  //         return (
  //           <a
  //             key={i}
  //             className="footer__top-link"
  //             href={link.url}
  //             target={link.type}
  //           >
  //             <div className="footer__top-link-content">
  //               <h3 className="footer__top-link-label H3WhiteDesktop">
  //                 {link.label}
  //               </h3>
  //               <DrawnArrow />
  //             </div>
  //             <Divider />
  //           </a>
  //         );
  //       })}
  //     </nav>
  //   );
  // };

  // const renderBottomLinks = () => {
  //   return (
  //     <nav className="footer__bottom-links" aria-label="Footer Navigation">
  //       {bottomlinks.map((link, i) => (
  //         <a
  //           key={i}
  //           href={link.url}
  //           className="footer__bottom-link CTAWhite"
  //           target="_blank"
  //         >
  //           {link.label}
  //         </a>
  //       ))}
  //     </nav>
  //   );
  // };

  const renderSocialLinks = () => (
    <nav className="footer__social" aria-label="Footer Social Navigation">
      <a
        href={MAIN_CONFIG.FOOTER.SOCIAL_LINKS.twitter}
        className="footer__social-link"
        target="_blank"
        alt="Twitter"
      >
        <Twitter />
      </a>
      <a
        href={MAIN_CONFIG.FOOTER.SOCIAL_LINKS.facebook}
        className="footer__social-link"
        target="_blank"
        alt="Facebook"
      >
        <Facebook />
      </a>
      <a
        href={MAIN_CONFIG.FOOTER.SOCIAL_LINKS.linkedin}
        className="footer__social-link"
        target="_blank"
        alt="Linkedin"
      >
        <Linkedin />
      </a>
    </nav>
  );

  //  return (
  //         <div className={`footer ${poweredByKaleidoscope ? 'powered-by' : ''}`}>
  //             <div className="footer__content">
  //                 {!poweredByKaleidoscope &&
  //                     <>
  //                         {/* <BrokenPencil hideLabel={false} /> */}
  //                         {heading && <h1 className="footer__heading H1DesktopWhite">{heading}</h1>}
  //                     </>
  //                 }
  //                 <div className="footer__links">

  //                     {!poweredByKaleidoscope ?
  //                         <>
  //                             {topLinks && renderTopLinks()}
  //                             {bottomlinks && renderBottomLinks()}
  //                             {renderSocialLinks()}
  //                         </>
  //                         :
  //                         <>
  //                         </>
  //                     }

  //                     <div>
  //                         {!poweredByKaleidoscope ?

  //                             <WordMark className="footer__logo" width="1102px" height="100%" color="white" />
  //                             :
  //                             <div className="powered-by-logo-container">
  //                                 <div className="">
  //                                     <BrandMark className="powered-by-logo-container__logo" />
  //                                 </div>
  //                                 <div className="powered-by-logo-container__logo-text">
  //                                     <WordMark className="" color="white" />
  //                                 </div>
  //                                 <div className="footer-powered-by">
  //                                     <div className="CTAWhite">Powered by Kaleidoscope.</div>
  //                                 </div>
  //                                 <div>
  //                                     {bottomlinks && renderBottomLinks()}
  //                                 </div>
  //                             </div>
  //                         }
  //                     </div>

  //                 </div>
  //             </div>
  //         </div>
  //     );

  return (
      <footer className="footer">
        {/* begin container */}
        <div className="container">
          <div className="footer-top">
            {/* Begin column one */}
            <div className="footer-top__column-one footer-col">
              <WordMark className='kaleidoscope-footer-logo' color={MAIN_CONFIG.COLORS.fillWhite} width={180} height={50} />
              <p class="copyright-small">
                &copy;Kaleidoscope {new Date().getFullYear()}
              </p>
              <div className="social-links">{renderSocialLinks()}</div>
              <div class="footer-buttons">
                <div
                  // tabIndex={0}
                  class="contact-us-btn"
                >
                  <a
                    tabIndex={0}
                    href="https://www.mykaleidoscope.com/company/contact-us/"
                    target="__blank">
                    Contact Us
                  </a>
                </div>
                {
                  (USER_CONTEXT.APPLICANT !== userContext) ?
                    <div class="login-btn">
                      <a
                        href="/login"
                      >
                        Login
                      </a>
                    </div>
                    : ""
                }
              </div>
            </div>
            {/* end column one */}
            {/* Begin column Two */}

            <div className="footer-top__column-two footer-col">
              <h1 className="footer-column-heading">PLATFORM</h1>
              <ul className="footer-column-list">
                <li>
                  <a
                    href="https://www.mykaleidoscope.com/for-sponsors/"
                    target="_blank"
                    alt="Find Sponsors"
                  >
                    For Sponsors
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mykaleidoscope.com/for-applicants/"
                    target="_blank"
                    alt="Find Applicants"
                  >
                    For Applicants
                  </a>
                </li>
                <li>
                  <a
                    href="http://apply.mykaleidoscope.com/"
                    target="_blank"
                    alt="Find Scholarships"
                  >
                    Find Scholarships
                  </a>
                </li>
              </ul>
            </div>

            {/* end column two */}
            {/* Begin column three */}
            <div className="footer-top__column-three footer-col">
              <h1 className="footer-column-heading">RESOURCES</h1>
              <ul className="footer-column-list">
                <li>
                  <a
                    href="http://help.mykaleidoscope.com/"
                    target="_blank"
                    alt="Help Center"
                  >
                    Help Center
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mykaleidoscope.com/resources/"
                    target="_blank"
                    alt="Insights"
                  >
                    Insights
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mykaleidoscope.com/resources/case-studies/"
                    target="_blank"
                    alt="Case Studies"
                  >
                    Case Studies
                  </a>
                </li>
              </ul>
            </div>
            {/* end column three */}
            {/* Begin column four */}
            <div className="footer-top__column-four footer-col">
              <h1 className="footer-column-heading">ABOUT US</h1>
              <ul className="footer-column-list">
                <li>
                  <a
                    href="https://www.mykaleidoscope.com/company/"
                    target="_blank"
                    alt="Company"
                  >
                    Company
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mykaleidoscope.com/company/culture/"
                    target="_blank"
                    alt="Culture"
                  >
                    Culture
                  </a>
                </li>
                <li>
                  <a
                    href="https://mykaleidoscope.applytojob.com/apply/?_gl=1*9sbzv1*_ga*MTg1MjAxMTc5NS4xNjUyMTI5OTAy*_ga_WDZGN4S8C6*MTY3NDE1NzMyNC40ODIuMS4xNjc0MTU5NjE2LjYwLjAuMA"
                    target="_blank"
                    alt="Careers"
                  >
                    Careers
                  </a>
                </li>
              </ul>
            </div>
            {/* end column four */}
          </div>
          <hr />
          <div className="footer-bottom">
            <div className="footer-bottom__left-section">
              <img
                src="https://mykldexportsprod.blob.core.windows.net/userreports/imports/kscope-site-assets/AICPA_Logo.webp"
                className="first-badge"
                alt="AICPA Logo"
              />
              <img
                src="https://mykldexportsprod.blob.core.windows.net/userreports/imports/kscope-site-assets/coppa_cert.webp"
                className="second-badge"
                alt="COPPA Logo"
              />
            </div>
            <div className="footer-bottom__right-section">
              <ul className="footer-navigation">
                <li>
                  <a
                    href="https://help.mykaleidoscope.com/"
                    target="_blank"
                    alt="Company"
                  >
                    Help
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mykaleidoscope.com/terms-of-use/"
                    target="_blank"
                    alt="Company"
                  >
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mykaleidoscope.com/privacy-policy/"
                    target="_blank"
                    alt="Company"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* end container */}
      </footer>
  );
};


