import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, width = 24, height = 24, color = MAIN_CONFIG.COLORS.fillGreen }) => (
    <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
        <title>LinkedIn</title>
        <desc>LinkedIn</desc>
        <g fill={color} fillRule="evenodd">
            <g fill={color}>
                <g>
                    <g>
                        <path
                            d="M20 19h-3.315v-4.922c0-1.288-.519-2.167-1.66-2.167-.871 0-1.356.593-1.582 1.165-.085.205-.072.491-.072.777V19h-3.284s.042-8.718 0-9.51h3.284v1.492c.194-.653 1.244-1.584 2.919-1.584 2.078 0 3.71 1.368 3.71 4.314V19zM6.766 8.3h-.022C5.686 8.3 5 7.573 5 6.65 5 5.711 5.706 5 6.786 5c1.079 0 1.742.71 1.763 1.649 0 .921-.684 1.65-1.783 1.65zM5.378 9.49h2.924V19H5.378V9.49z"
                            transform="translate(-296 -767) translate(164 767) translate(132)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
